<template>
   <div class="card mb-2">
      <div class="card-body pb-0">
         <div class="row">
            <div class="col mb-3">
               <label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
               <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarClientes" placeholder="Busque por nome do cliente, CPF ou RG" />
            </div>
            <div class="px-0 mb-3 align-self-end" :class="showAdd ? 'col-12 col-sm-5 col-md-3 col-xl-2' : 'col-2'">
               <div class="row">
                  <div class="col">
                     <button type="button" class="btn btn-primary w-100" @click="buscarClientes"><i class="fas fa-search font-13"></i></button>
                  </div>
                  <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar clientes') && showAdd">
                     <button type="button" class="btn btn-primary w-100" @click="adicionar"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'PesquisaCliente',
   props: ['showAdd'],
   data: function () {
		return {
			pesquisa: {'clubeDesconto': null, 'nome': ''}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      adicionar : function () {
         this.$emit('adicionar')
      },
      buscarClientes : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/searchClientes',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.$emit('setRetorno', response.data)

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
   }
}

</script>