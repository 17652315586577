<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Gerencie os clientes do seu clube de desconto, ecommerce e/ou afiliados. Adicione, atualize ou delete. Atribua um cliente a um clube de desconto.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
			<pesquisaCliente class="dropdown-menu dropdown-menu-left" ref="pesquisaCliente" :showAdd="true" @setRetorno="setRetornoPesquisa($event)" @adicionar="abrirModal(null)" />
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<cliente v-for="(cliente, index) in pesquisa.resultado" :key="index" :cliente="cliente" :index="index" :tela="'CLIENTES'" @editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalCliente -->
      <div class="modal fade" id="modalCliente" tabindex="-1" aria-labelledby="modalClienteLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalClienteLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} cliente {{ modal.id == null ? '' : ('('+ modal.nomeFantasia +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="accordion" id="accordionExample">
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne">
									<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i class="fal fa-home"></i> Informações básicas
									</button>
								</h2>
								<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-percentage color-theme font-12 me-1"></i> Clube de desconto</label>
												<v-select name="Desktop" :options="pesquisa.clubesDesconto" v-model="modal.clubeDesconto" label="nome" :reduce="clube => clube.id" placeholder="Selecione uma opção">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Clube de desconto" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.clubeDesconto">
													<option v-for="(option, index) in pesquisa.clubesDesconto" :key="index" :value="option.id">{{ option.nome }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-truck color-theme font-12 me-1"></i> Nome *</label>
												<input type="text" class="form-control" v-model="modal.nomeFantasia" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF/CNPJ *</label>
												<input type="text" class="form-control" v-model="modal.cpfCnpj" v-mask="['###.###.###-##', '##.###.###/####-##']" />
											</div>
											<div class="col-sm-6 mb-3" v-if="modal.pessoaFisica != true">
												<label><i class="far fa-building color-theme font-11 me-1"></i> Razão social *</label>
												<input type="text" class="form-control" v-model="modal.razaoSocial" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3" v-if="modal.pessoaFisica != true">
												<label><i class="far fa-calculator color-theme font-11 me-1"></i> Inscrição estadual</label>
												<input type="text" class="form-control" :value="'ISENTO'" disabled v-if="modal.pessoaFisica" />
												<input type="text" class="form-control" v-model="modal.inscricaoEstadual" v-mask="'#########################'" v-else />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email</label>
												<input type="text" class="form-control" v-model="modal.email" maxlength="200" />
											</div>
											<div class="col-sm-6 align-self-center">
												<div class="form-check mb-0">
													<input type="checkbox" class="form-check-input" id="pessoaFisica" v-model="modal.pessoaFisica">
													<label class="form-check-label" for="pessoaFisica"><i class="far fa-male font-13 color-theme mx-1"></i> Pessoa fisíca</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingTwo">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										<i class="fal fa-map-marker-alt me-3"></i>Endereço
									</button>
								</h2>
								<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-4 mb-3">
												<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
												<div class="input-group">
													<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
													<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
														<i class="fas fa-search"></i>
													</a>
												</div>
											</div>
											<div class="col-sm-8 mb-3">
												<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
												<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
											</div>
											<div class="col-sm-4 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
											</div>
											<div class="col-sm-8 mb-3">
												<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
												<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
												<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
												<v-select name="Desktop" :options="estados" v-model="modal.endereco.estado" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modal.endereco.estado">
													<option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
												<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
											</div>
											<div class="col-sm-8">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
												<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingThree">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										<i class="fal fa-phone-alt"></i> Telefone
									</button>
								</h2>
								<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-3 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
												<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
											</div>
											<div class="col-sm-5 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
												<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
													<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-12">
												<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
												<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item" v-if="dadosUsuario.usuarioPacotes.includes('Convênios')">
								<h2 class="accordion-header" id="headingFour">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
										<i class="fal fa-hand-holding-usd"></i> Convênio
									</button>
								</h2>
								<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-money-bill-wave-alt color-theme font-11 me-1"></i> Valor limite</label>
												<input type="text" class="form-control" v-model="modal.valor" v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']" :disabled="!modal.convenioHabilitado">
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
												<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="modal.lojaConvenio" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção" :disabled="!modal.convenioHabilitado">
													<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.lojaConvenio" :disabled="!modal.convenioHabilitado">
													<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3 align-self-center" v-if="modal.id">
												<label class="cursor-pointer mb-0 mt-2 mt-sm-0" data-bs-toggle="modal" data-bs-target="#modalSenha" @click="config.cadastrado = modal.codigoConvenio != null;">
													<i class="far fa-long-arrow-right me-1"></i> {{ modal.codigoConvenio != null ? 'Redefinir' : 'Definir' }} senha de convênio
												</label>
											</div>
											<div class="col-sm-6 mb-3" v-else>
												<label><i class="far fa-barcode color-theme font-11 me-1"></i> Senha</label>
												<input type="text" class="form-control key" v-model="modal.codigoConvenio" maxlength="200" :disabled="!modal.convenioHabilitado" />
											</div>
											<div class="col-sm-6 mb-3 align-self-center">
												<label v-if="modal.id == null"><span class="d-none d-sm-block">&nbsp;</span></label>
												<div class="form-check mb-1">
													<input type="checkbox" class="form-check-input" id="convenio" v-model="modal.convenioHabilitado">
													<label class="form-check-label cursor-pointer" for="convenio">
														<span class="limitador-1"><i class="far fa-hand-holding-usd font-13 color-theme mx-1"></i> Habilitar/Desabilitar Convênio</span>
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="alert alert-secondary alert-dismissible fade show mb-0 shadow-none pe-3 py-12 font-13" role="alert">
													<strong>Convênio</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
													<span>Defina um limite de crédito que permita o usuário gastar no seu estabelecimento. O valor utilizado pode ser restaurado na aba "Convênios" > "Afiliados" do menu superior.</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarCliente">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalSenha -->
      <div class="modal fade" id="modalSenha" tabindex="-1" aria-labelledby="modalSenhaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalSenhaLabel">{{ config.cadastrado ? 'Redefinir' : 'Definir' }} senha convênio</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalUsuario" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="col-12 mb-3" v-if="config.cadastrado">
									<label><i class="far fa-barcode color-theme font-12 me-1"></i> Código atual *</label>
									<input type="text" class="form-control key" v-model="config.atual" maxlength="200" />
								</div>
								<div class="col-12">
									<label><i class="far fa-barcode color-theme font-12 me-1"></i> Código novo *</label>
									<input type="text" class="form-control key" v-model="config.novo" maxlength="200" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarSenha">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalUsuario">Fechar</button>
               </div>
				</div>
			</div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import cliente from '@/components/automacao/clientes/Cliente.vue'
import pesquisaCliente from '@/components/automacao/PesquisaCliente.vue'

export default {
	name: 'Clientes',
	data: function () {
		return {
			pesquisa: {'nome': null, 'clubesDesconto': [], 'resultado': []},
			modal: {
				'id': null, 'cpfCnpj': null, 'inscricaoEstadual': null, 'nomeFantasia': null, 'razaoSocial': null, 'pessoaFisica': false, 'email': null, 
				'regimeTributario': null, 'clubeDesconto': null,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'codigoConvenio': null, 'convenioHabilitado': false, 'valor': null, 'lojaConvenio': null
			},
			config: {'novo': null, 'atual': null, 'id': null, 'cadastrado': false}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         estados: state => state.estados,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		cliente, pesquisaCliente
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Clientes',
				'resultado': this.pesquisa.resultado, 
				'campos': ['id', 'nomeFantasia', 'razaoSocial', 'pessoaFisica', 'cpfCnpj', 'email', 'clubeDesconto'], 
				'lista': null
			})
      },
		abrirModal : function (cliente) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/searchClubesDesconto',
				headers: {'Content-Type': 'application/json'},
				data: {'loja': null, 'status': null, 'nome': ''}

			}).then(response => {
				this.config = {'id': cliente == null ? null : cliente.id, 'cadastrado': false, 'atual': null, 'novo': null}
				this.pesquisa.clubesDesconto = response.data.resultado
				$('#modalCliente').modal('show')

				if (cliente == null) {
					Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
					
				} else {
					this.modal = {
						'id': cliente.id, 
						'cpfCnpj': cliente.cpfCnpj, 
						'inscricaoEstadual': cliente.inscricaoEstadual, 
						'nomeFantasia': cliente.nomeFantasia, 
						'razaoSocial': cliente.razaoSocial, 
						'pessoaFisica': cliente.pessoaFisica, 
						'email': cliente.email, 
						'regimeTributario': cliente.regimeTributario, 
						'clubeDesconto': cliente.clubeDesconto,
						'endereco': cliente.endereco, 
						'telefone': cliente.telefone,
						'convenioHabilitado': cliente.convenioHabilitado, 
						'codigoConvenio': cliente.codigoConvenio, 
						'lojaConvenio': cliente.lojaConvenio, 
						'valor': cliente.valor == null ? parseFloat(0).toFixed(2) : parseFloat(cliente.valor).toFixed(2)
					}
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		setRetornoPesquisa : function (retorno) {
			this.pesquisa = this.$options.data.call(this).pesquisa;
			setTimeout(() => this.pesquisa = retorno, 50);
		},
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarCliente : function () {
			if (this.modal.cpfCnpj == null || String(this.modal.cpfCnpj).trim().length == 0 ||
				this.modal.nomeFantasia == null || String(this.modal.nomeFantasia).trim().length == 0 ||
				(this.modal.pessoaFisica != true && (this.modal.razaoSocial == null || String(this.modal.razaoSocial).trim().length == 0))) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			if (this.modal.pessoaFisica) this.modal.inscricaoEstadual = 'ISENTO'

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/saveCliente',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalCliente').modal('hide')
				this.$refs.pesquisaCliente.buscarClientes()

				this.$toast.fire({
					icon: 'success',
					title: 'Cliente salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarSenha : function () {
			if ((this.config.cadastrado && (this.config.atual == null || String(this.config.atual).trim().length == 0)) || 
				this.config.novo == null || String(this.config.novo).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('salvarSenhaUsuario', this.config).then(() => {
				$('#modalSenha').modal('hide')
				this.modal.codigoConvenio = JSON.parse(JSON.stringify(this.config.novo))

				this.config = {'novo': null, 'atual': null, 'id': this.config.id, 'cadastrado': false}
				$('#modalCliente').modal('show')
			})
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>